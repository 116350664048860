import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import {environment} from '../../environments/environment';
import {forkJoin, Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {CurrentUser, Rights} from './security.service';
import * as Sentry from '@sentry/browser';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  private LOCAL_STORE_SINGLE_DATE   = 'single_date';
  private LOCAL_STORE_PERIOD        = 'period';
  private LOCAL_STORE_COUNTRY       = 'country';
  private LOCAL_STORE_OVERLAY_STATE = 'overlay';
  private LOCAL_STORE_GIS_GRID_STATE = 'gis_grid_state';

  private OVERLAY_TEMPLATE = [
    {name: 'Трек', checked: true, id: '8c4e3b42-9978-11e9-a2a3-2a2ae2dbcce4'},
    {name: 'Клиенты', checked: true, id: 'b32c2328-9978-11e9-a2a3-2a2ae2dbcce4'},
    {name: 'Документы', checked: true, id: 'c34a185a-9978-11e9-a2a3-2a2ae2dbcce4'},
    {name: 'Фотографии', checked: true, id: 'db93da94-3af7-4f4f-9843-6efe03268e85'},
    {name: 'Филиалы', checked: true, id: '24051089-09cb-4e52-8cf2-b8a3f843629c'}
  ];

  private GIS_GRID_TEMPLATE = 'traders' ;
  private rightsList: Rights[];
  private countriesList: Set<string>;

  private period: Period;
  private singleDate: Moment;
  private country: string;
  private traderId: number;
  private overlayStateArray: OverlayState[] = [];
  private singleDateChangeSubject = new Subject<Moment>();
  private periodChangeSubject = new Subject<Period>();
  private countryChangeSubject = new Subject<string>();
  private httpErrorSubject = new Subject<HttpError>();
  private traderChangeSubject = new Subject<number>();
  private dataLoadProgress = new Subject<boolean>();
  private overlayStateSubject = new Subject<OverlayState>();
  private canCloseGalleryDialog = true;
  private gisAgGridType: string;
  private gisAgGridTypeChangeSubject = new Subject<string>();

  constructor(private http: HttpClient) { }

  init(): Observable<void> {
    return new Observable(observer => {
      if (!this.loadPeriod()) {
        this.period = {startDate: moment(), endDate: moment()};
        this.storePeriod();
      }
      if (!this.loadSingleDate()) {
        this.singleDate = moment().startOf('day');
        this.storeSingleDate();
      }
      if (!this.loadOverlayState()) {
        this.overlayStateArray = this.overlayStateArray.concat(this.OVERLAY_TEMPLATE);
        this.storeOverlayState();
      }
      if (!this.loadGisAgGridType()) {
        this.gisAgGridType = this.GIS_GRID_TEMPLATE;
        this.storeGisAgGridState();
      }
      forkJoin([
        this.http.get<CurrentUser>(environment.apiURL + 'security/current_user'),
        this.http.get<Branch[]>(environment.apiURL + 'branch/list')
      ]).subscribe(value => {
        this.rightsList = value[0].rights.map(value1 => Rights[value1]);
        this.countriesList = new Set<string>(value[1].map(value1 => value1.country));
        localStorage.setItem('current_user_id', value[0].id);
        // Настройка пользователя в Sentry
        Sentry.setUser({
          id: value[0].id,
          username: value[0].name,
        });
        if (!this.loadCountry()) {
          this.country = this.countriesList.size > 0 ? this.countriesList.values().next().value : null;
          this.storeCountry();
        }
        observer.next();
        observer.complete();
      }, () => {
        observer.error();
        observer.complete();
      });
    });
  }

  // Управление датой
  setSingleDate(date: Moment) {
    this.singleDate = date;
    this.storeSingleDate();
    this.singleDateChangeSubject.next(this.singleDate);
  }
  getSingleDate() { return this.singleDate; }

  // Управление периодом
  setPeriod(startDate: Moment, endDate: Moment) {
    this.period = {startDate: startDate, endDate: endDate};
    this.storePeriod();
    this.periodChangeSubject.next(this.period);
  }
  getPeriod() { return this.period; }
  getPeriodStartDate() { return this.period.startDate; }
  getPeriodEndDate() { return this.period.endDate; }

  // Управление страной
  setCountry(country: string) {
    this.country = country;
    this.storeCountry();
    this.countryChangeSubject.next(this.country);
  }
  getCountry() {
    return this.country;
  }
  getCountriesList() {
    return this.countriesList;
  }

  setHttpError(httpError: HttpError) {
    this.httpErrorSubject.next(httpError);
  }

  setOverlayState(overlayStateArray: OverlayState[]) {
    this.overlayStateArray = overlayStateArray;
    this.storeOverlayState();
  }
  getOverlayState() {
    return this.overlayStateArray;
  }

  onChangeSingleDate() {
    return this.singleDateChangeSubject.asObservable();
  }
  onChangePeriod() {
    return this.periodChangeSubject.asObservable();
  }
  onChangeCountry() {
    return this.countryChangeSubject.asObservable();
  }
  onChangeOverlayState() {
    return this.overlayStateSubject.asObservable();
  }
  onHttpError() {
    return this.httpErrorSubject.asObservable();
  }

  hasRights(...rights: Rights[]): boolean {
    return this.rightsList.some(v => rights.includes(v));
  }

  setTraderId(traderId: number) {
    this.traderId = traderId;
    this.traderChangeSubject.next(this.traderId);
  }

  onChangeTraderId() {
    return this.traderChangeSubject.asObservable();
  }

  setGisAgGridType(gisAgGridType: string) {
    this.gisAgGridType = gisAgGridType;
    this.storeGisAgGridState();
    this.gisAgGridTypeChangeSubject.next(this.gisAgGridType);
  }

  getGisAgGridType(): string {
    return this.gisAgGridType;
  }

  private storeGisAgGridState() {
    localStorage.setItem(this.LOCAL_STORE_GIS_GRID_STATE, JSON.stringify(this.gisAgGridType));
  }

  loadGisAgGridType(): boolean {
    const strGisGridStateState = localStorage.getItem(this.LOCAL_STORE_GIS_GRID_STATE);
    if (strGisGridStateState == null) { return false; }
    this.gisAgGridType = JSON.parse(strGisGridStateState);
    return true;
  }

  setDataLoadingProgress(value: boolean) {
    this.dataLoadProgress.next(value);
  }
  onLoadDataProgress() {
    return this.dataLoadProgress.asObservable();
  }

  private storePeriod() {
    localStorage.setItem(this.LOCAL_STORE_PERIOD, JSON.stringify(this.period));
  }

  private storeSingleDate() {
    localStorage.setItem(this.LOCAL_STORE_SINGLE_DATE, this.singleDate.toISOString(false));
  }

  private storeCountry() {
    localStorage.setItem(this.LOCAL_STORE_COUNTRY, this.country);
  }

  private storeOverlayState() {
    localStorage.setItem(this.LOCAL_STORE_OVERLAY_STATE, JSON.stringify(this.overlayStateArray));
  }

  private loadPeriod(): boolean {
    const strPeriod = localStorage.getItem(this.LOCAL_STORE_PERIOD);
    if (strPeriod == null) { return false; }
    const objPeriod = JSON.parse(strPeriod);
    this.period = {startDate: moment(objPeriod.startDate), endDate: moment(objPeriod.endDate)};
    return true;
  }

  private loadSingleDate(): boolean {
    const strSingleDate = localStorage.getItem(this.LOCAL_STORE_SINGLE_DATE);
    if (strSingleDate == null) { return false; }
    this.singleDate = moment(strSingleDate);
    return true;
  }

  private loadCountry(): boolean {
    this.country = localStorage.getItem(this.LOCAL_STORE_COUNTRY);
    return this.country != null && this.countriesList.has(this.country);
  }

  private loadOverlayState(): boolean {
    const strOverlayState = localStorage.getItem(this.LOCAL_STORE_OVERLAY_STATE);
    if (strOverlayState == null) { return false; }
    this.overlayStateArray = JSON.parse(strOverlayState);
    return this.overlayStateArray.length === this.OVERLAY_TEMPLATE.length;
  }

  setGalleryDialogClosePermission(value: boolean) {
    this.canCloseGalleryDialog = value;
  }
  getGalleryDialogClosePermission() {
    return this.canCloseGalleryDialog;
  }
}

/**
 * Модель периода
 */
export interface Period {
  startDate: Moment;
  endDate: Moment;
}

/**
 * Модель филиала
 */
export interface Branch {
  id: number;
  name: string;
  shortName: string;
  region: string;
  country: string;
}

/**
 * Модель ошибки выполнения запроса на сервер
 */
export interface HttpError {
  date: string;
  message: string;
  details: string;
}

/**
 * Модель валюты
 */
export interface Currency {
  id: number;
  name: string;
  code: string;
  shortName: String;
}

/**
 * Модель состояния слоя
 */
export interface OverlayState {
  name: string;
  checked: boolean;
  id: string;
}
