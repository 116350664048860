import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: 'dash',
    loadChildren: () => import('./content/pages/dashboard/dashboard.module').then(mod => mod.DashboardModule)
  },
  {
    path: 'gallery',
    loadChildren: () => import('./content/pages/gallery/gallery.module').then(mod => mod.GalleryModule)
  },
  {
    path: 'files',
    loadChildren: () => import('./content/pages/files/files.module').then(mod => mod.FilesModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./content/pages/reports/reports.module').then(mod => mod.ReportsModule)
  },
  {
    path: 'devices',
    loadChildren: () => import('./content/pages/devices/devices.module').then(mod => mod.DevicesModule)
  },
  {
    path: 'gis-monitoring',
    loadChildren: () => import('./content/pages/gis-monitoring/gis-monitoring.module').then(mod => mod.GisMonitoringModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./content/pages/users/users.module').then(mod => mod.UsersModule)
  },
  { path: '', redirectTo: 'dash', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
