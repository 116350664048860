<app-navbar></app-navbar>
<div id="wrapper">
  <app-sidebar class="sidebar navbar-nav toggled"></app-sidebar>
  <router-outlet></router-outlet>
</div>
<div aria-live="polite" aria-atomic="true" class="d-flex justify-content-center align-items-center"
     style="min-height: 100px; position: fixed; right: 15px; bottom: 15px; z-index: 100; pointer-events: none;">
  <div id="errorToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true" style="min-width: 200px; pointer-events: none;">
    <div class="toast-body alert-danger">
      Ошибка доступа к серверу
    </div>
  </div>
  <div id="successToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true" style="min-width: 200px; pointer-events: none;">
    <div class="toast-body alert-success">
      Готово!
    </div>
  </div>
</div>
