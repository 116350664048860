import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {UiService} from '../services/ui.service';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  constructor(private uiService: UiService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpResponse<any>) => event),
      catchError((error: HttpErrorResponse) => {
        this.uiService.setHttpError(error.error);
        return throwError(error);
      })
    );
  }
}
