import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthService} from '../auth.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {TokenInterceptor} from '../token.interceptor';

export function oauth2InitApp(auth: AuthService) {
  return () => auth.appInitialize();
}

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    AuthService,
    { provide: APP_INITIALIZER, useFactory: oauth2InitApp, deps: [AuthService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ]
})
export class Oauth2Module { }
