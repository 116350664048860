import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {UiService} from '../../../services/ui.service';
import {Rights} from '../../../services/security.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {
  selectedItem = 'menu-browse';

  showDashboard = false;
  showGallery = false;
  showFiles = false;
  showDevices = false;
  showGisMonitoring = false;
  showReports = false;
  showUsers = false;

  private subscription: Subscription;

  constructor(private router: Router,
              private uiService: UiService) { }

  ngOnInit() {
    this.showDashboard = this.uiService.hasRights(Rights.VIEW_DASHBOARD);
    this.showReports = this.uiService.hasRights(Rights.VIEW_REPORTS, Rights.EDIT_REPORTS);
    this.showUsers = this.uiService.hasRights(Rights.ADMIN_USERS_AND_ROLES);
    this.showGallery = this.uiService.hasRights(Rights.VIEW_MT_GALLERY);
    this.showFiles = this.uiService.hasRights(Rights.VIEW_MT_FILES);
    this.showDevices = this.uiService.hasRights(Rights.VIEW_MT_DEVICES);
    this.showGisMonitoring = this.uiService.hasRights(Rights.VIEW_MT_GPS_MONITORING);
    this.subscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((route: NavigationEnd) => {
        if (route.url.includes('reports')) {
          this.selectedItem = 'menu-reports';
        } else if (route.url.includes('devices')) {
          this.selectedItem = 'menu-devices';
        } else if (route.url.includes('gis-monitoring')) {
          this.selectedItem = 'menu-gis-monitoring';
        } else if (route.url.includes('users')) {
          this.selectedItem = 'menu-users';
        } else if (route.url.includes('gallery')) {
          this.selectedItem = 'menu-gallery';
        } else if (route.url.includes('files')) {
          this.selectedItem = 'menu-files';
        } else {
          this.selectedItem = 'menu-browse';
        }
      });
  }

  ngOnDestroy(): void {
    if (this.subscription != null) { this.subscription.unsubscribe(); }
  }
}
