export const locale = {
  commons: {
    create: 'Создать',
    cancel: 'Отмена',
    save: 'Сохранить',
    close: 'Закрыть',
    change: 'Изменить',
    add: 'Добавить',
    remove: 'Удалить',
    yes: 'Да',
    no: 'Нет',
    fileName: 'Имя файла',
    loading: 'Загрузка',
    dateFormat: 'DD.MM.YYYY',
    timeHMSFormat: 'HH:mm:ss',
    timeHMFormat: 'HH:mm',
    dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
    durationFormat: 'd д. hh:mm:ss',
    kilogram: 'кг',
    removedDocument: 'Удален/Не проведен',
    applyDocument: 'Проведен',
    linksTypes: {
      UNKNOWN: 'Неизвестно',
      /**
       * Обмен с сервером
       */
      IMPORT: 'Импорт',
      IMPORT_OK: 'Импорт (успех)',
      IMPORT_ERROR: 'Импорт (ошибка)',
      IMPORT_CANCEL: 'Импорт (отмена)',
      EXPORT: 'Экспорт',
      EXPORT_PARTIAL: 'Экспорт (выборочный)',
      EXPORT_OK: 'Экспорт (успех)',
      EXPORT_ERROR: 'Экспорт (ошибка)',
      EXPORT_CANCEL: 'Экспорт (отмена)',
      BOOT: 'Перезагрузка',
      /**
       * Фотосторчеки
       */
      PHOTO_CLIENT: 'Фото ТРТ',
      PHOTO_NOTE: 'Фотозаметка',
      PHOTO_AUTO: 'Фото одометра (по маршруту)',
      PHOTO_AUTO_HOME: 'Фото одометра (из дома / домой)',
      PHOTO_FRIDGE: 'Фото ТХО',
      PHOTO_PRODUCTS: 'Фото выкладки',
      PHOTO_PRODUCTS_COMPETITOR: 'Фото выкладки конкурента',
      /**
       * Документы
       */
      DOC_REMAINS: 'Остатки',
      DOC_ORDER: 'Заказ',
      DOC_PAYMENT: 'Оплата',
      DOC_REALIZATION: 'Реализация',
      DOC_RETURN: 'Заказ на возврат',
      DOC_VISIT: 'Посещение'
    },
    documentCreateDislocation: {
      INSIDE_CLIENT: 'На клиенте',
      OUTSIDE_CLIENT: 'Вне клиента',
      UNKNOWN: 'Неизвестно'
    }
  },
  root: {
    title: 'Аналитика',
    browse: 'Обзор',
    sale: 'Реализация',
    debt: 'Дебиторка',
    profit: 'Маржа',
    logout: 'Выход',
    footer: 'ООО ТД "Горняк" © Дистрибуция 2018',
    dateRanges: {
      today: 'Сегодня',
      yesterday: 'Вчера',
      theDayBeforeYesterday: 'Позавчера',
      currentWeek: 'Текущая неделя',
      currentMonth: 'Текущий месяц',
      currentYear: 'Текущий год',
      lastWeek: 'Прошлая неделя',
      lastMonth: 'Прошлый месяц',
      lastYear: 'Прошлый год'
    },
    sideMenu: {
      browse: 'Обзор',
      gallery: 'Галерея',
      files: 'Файлы',
      reports: 'Отчеты',
      devices: 'Устройства',
      gisMonitoring: 'GIS-мониторинг',
      users: 'Пользователи и роли'
    }
  },
  dash: {
    sales: 'Реализация',
    pays: 'Оплаты',
    order: 'Заказ',
    orderF1: 'Заказ Ф1',
    orderF2: 'Заказ Ф2',
    return: 'Возврат',
    returnF1: 'Возврат Ф1',
    returnF2: 'Возврат Ф2',
    returnsByBranches: 'Возвраты по филиалам',
    shipment: 'Отгрузка',
    shipmentWeight: 'Масса отгрузки',
    shipmentByBranches: 'Отгрузка по филиалам',
    shipmentBySalesChannels: 'Отгрузка по каналам сбыта',
    shipmentByProductsGroups: 'Отгрузка по категориям товаров',
    shipmentByTrademarksMoney: 'Отгрузка по типу ТМ (деньги)',
    shipmentByTrademarksWeight: 'Отгрузка по типу ТМ (масса)',
    shipmentF1: 'Отгрузка Ф1',
    shipmentF2: 'Отгрузка Ф2',
    clientsCountByShipmentsCount: 'Количество клиентов по количеству отгрузок',
    top20ProductsByShipment: 'ТОП 20 товаров по отгрузке',
    top20ProductsByShipmentColumns: {
      code: 'Код',
      product: 'Товар',
      sum: 'Сумма',
      weight: 'Масса'
    },
    top20ProductsByReturns: 'ТОП 20 товаров по возвратам',
    top20ProductsByReturnsColumns: {
      code: 'Код',
      product: 'Товар',
      sum: 'Сумма',
      weight: 'Масса'
    },
    debt: 'Дебиторка (на конец периода)',
    debtDynamic: 'Динамика изменения дебиторской задолженности',
    debtDynamicByBranches: 'Дебиторская задолженность по филиалам на конец периода',
    debtDynamicByBranchesColumns: {
      branchName: 'Филиал',
      overdueSum: 'Просроченная',
      percentOverdue: '% просроченной',
      notOverdueSum: 'Не просроченная',
      percentNotOverdue: '% не просроченной',
      sum: 'Общая'
    },
    total: 'Итого: {{value}}',
    profit: 'Маржа',
    profitPercent: '% маржи',
    profitPercentToTotal: '% к общей марже',
    profitPercentByBranchesToTotal: 'Процент маржи по филиалам к общей марже',
    profitPercentByProductGroupToTotal: 'Процент маржи по группам товаров к общей марже',
    profitPercentBySalesChannelToTotal: 'Процент маржи по каналам сбыта к общей марже',
    profitByProductGroupsToShipment: 'Процент маржи по группам товаров к отгрузке',
    profitBySalesChannelToToShipment: 'Процент маржи по каналам сбыта к отгрузке',
    profitByProductGroupsToShipmentColumns: {
      group: 'Группа',
      shipment: 'Отгрузка',
      profit: 'Маржа',
      profitPercent: '% маржи'
    },
    profitBySalesChannelToToShipmentColumns: {
      salesChannel: 'Канал сбыта',
      shipment: 'Отгрузка',
      profit: 'Маржа',
      profitPercent: '% маржи'
    },
    productsCategories: 'Категории товаров',
    salesChannels: 'Каналы сбыта',
    details: 'Детали'
  },
  devices: {
    title: 'Устройства',
    columns: {
      branchName: 'Филиал',
      deviceId: 'ID устройства',
      deviceModel: 'Модель устройства',
      osVersionName: 'Версия Android',
      softVersionName: 'Версия ПО',
      gmsVersionName: 'Версия сервисов Google',
      uptime: 'Аптайм',
      traderName: 'Торговый представитель'
    },
    detailsTitle: '{{traderName}} ({{deviceModel}}; {{deviceId}})',
    detailsTabs: {
      actions: 'Журнал операций',
      gisMetrics: 'GIS метрики',
      batMetrics: 'Метрики батареи'
    },
    photo: 'Фото',
    detailsActionsColumns: {
      dateTime: 'Дата/Время',
      actionType: 'Действие',
      linkType: 'Операция',
      clientName: 'Клиент'
    },
    actionsTypes: {
      CREATE: 'Создание/Выполнение',
      UPDATE: 'Изменение',
      DELETE: 'Удаление'
    },
    detailsGISColumns: {
      dateTime: 'Дата/Время',
      status: 'Состояние',
      firstFixDelayMillis: 'Фиксация первой координаты (сек)',
      satellitesInfo: 'Информация по спутникам'
    },
    gisMetrics: {
      ENABLED: 'Включен',
      DISABLED: 'Выключен',
      STARTED: 'Поиск спутников начат',
      STOPPED: 'Поиск спутников прекращен',
      FIRST_FIX: 'Фиксация первой координаты',
      SATELLITE_STATUS_CHANGED: 'Смена статуса спутников'
    },
    detailBATColumns: {
      dateTime: 'Дата/Время',
      batteryExists: 'Наличие батареи',
      technology: 'Химия',
      temperature: 'Температура',
      voltage: 'Напряжение',
      chargeLevel: 'Уровень заряда',
      health: 'Здоровье',
      powerSource: 'Источник питания',
      state: 'Состояние'
    },
    batHealth: {
      COLD: 'Переохлаждение',
      DEAD: 'Вышла из строя',
      GOOD: 'Хорошее',
      OVER_VOLTAGE: 'Перезаряд',
      OVERHEAT: 'Перегрев',
      UNKNOWN: 'Не удалось установить',
      UNKNOWN_FAILURE: 'Неизвестный сбой в работе батареи'
    },
    batPowerSource: {
      USB: 'USB порт компьютера',
      AC: 'Зарядное устройство',
      WIRELESS: 'Беспроводное зарядное устройство',
      BATTERY: 'Батарея'
    },
    batState: {
      CHARGING: 'Заряжаертся',
      DISCHARGING: 'Разряжается',
      FULL: 'Полностью заряжен',
      NOT_CHARGING: 'Не заряжается',
      UNKNOWN: 'Не удалось установить'
    }
  },
  files: {
    title: 'Файлы',
    types: {
      BACKUP: 'Резервные копии',
      REPORT: 'Отчеты'
    },
    columns: {
      name: 'Наименование'
    }
  },
  gallery: {
    filters: {
      period: 'Период',
      branch: 'Филиал',
      photo_type: 'Тип Фото',
      terra: 'Терра',
      sv: 'СВ',
      tp: 'ТП',
      client: 'Клиент',
      product_type: 'Вид продаж',
      fridge: 'ТХО',
      photo_note_type: 'Тип заметки'
    },
    sheetPhotoMemoTitle: 'Примечание к фотографии'
  },
  gisMonitoring: {
    clientTitle: 'Клиент',
    traders: 'Торговые представители',
    sectors: 'Участки',
    dialogClientsByRouteTitle: 'Клиенты по маршруту',
    dialogClientsColumns: {
      name: 'Название',
      location: 'Локация',
      lat: 'Широта',
      lon: 'Долгота'
    },
    dialogDocumentsTitle: 'Документы',
    dialogFullDocumentTitle: 'Детали документа',
    dialogDocumentsColumns: {
      deviceModel: 'Модель устройства',
      clientName: 'Клиент',
      time: 'Время',
      documentType: 'Тип документа',
      productTypeName: 'Вид продукции',
      createDislocation: 'Место создания'
    },
    dialogGalleryTitle: 'Фотографии',
    dialogTracksTitle: 'Треки',
    dialogTracksColumns: {
      deviceModel: 'Модель устройства/Участок трека',
      distance: 'Дистанция, км'
    },
    markerType: {
      start: 'Начало маршрута: ',
      finish: 'Конец маршрута: ',
      fromHome: 'Фото одометра при выезде из дома: ',
      toHome: 'Фото одометра при поездке домой: '
    },
    trackDetails: {
      routeDistanceTooltip: 'Дистанция по маршруту',
      clientsCountTooltip: 'Количество клиентов',
      startTrackTooltip: 'Время старта',
      finishTrackTooltip: 'Время финиша',
      documentsCountTooltip: 'Количество документов',
      photoCountTooltip: 'Количество фотографий'
    },
    trackSegmentType: {
      outside: 'Вне маршрута',
      onFoot: 'Пешком',
      inVehicle: 'На машине',
      fromToHome: 'Из дома/домой',
      totalOnRoute: 'Итого по маршруту'
    },
    dialogFullDocumentWeight: 'Итого вес',
    dialogFullDocumentSum: 'Сумма документа',
    dialogFullDocumentColumns: {
      price: 'Цена',
      quantity: 'Количество',
      tax: 'Налог',
      sum: 'Сумма',
      code: 'Код',
      unit: 'Единица измерения'
    },
    documentStatus: 'Статус документа',
    osm: {
      layerOSM: 'OSM',
      layerBranches: 'Филиалы',
      layerTrack: 'Трек',
      layerClients: 'Клиенты',
      layerDocuments: 'Документы',
      layerPhotos: 'Фотографии'
    },
    menu: {
      collapse: 'Свернуть',
      expand: 'Развернуть',
      collapseAll: 'Свернуть все',
      expandAll: 'Развернуть все'
    },
    columns: {
      branchName: 'Название филиала/ФИО'
    },
    documentCreateDislocationTooltipMessage: {
      INSIDE_CLIENT: 'Документ был создан, когда торговый представитель находился у клиента',
      OUTSIDE_CLIENT: 'Документ был создан, когда торговый представитель находился вне клиента',
      UNKNOWN: 'Невозможно определить координаты создания документа или клиента'
    },
    photoCreateDislocationTooltipMessage: {
      INSIDE_CLIENT: 'Фото было создано, когда торговый представитель находился у клиента',
      OUTSIDE_CLIENT: 'Фото было создано, когда торговый представитель находился вне клиента',
      UNKNOWN: 'Невозможно определить координаты создания фото или клиента'
    },
    leafletMeasuringRulerText: {
      tooltipLineOnHover: 'Нажмите л.к.м. или потяните за линию для создания маркера<br>',
      tooltipMarkerOnHover: 'Нажмите л.к.м. для удаления маркера<br>Потяните маркер для его перемещения<br>',
      rulerIconTitle: 'Измерить расстояние',
      clearIconTitle: 'Очистить измерения',
      title: {
        text: 'Изменить единицы измерения',
        kilometres: 'километры',
        landmiles: 'мили',
        nauticalmiles: 'морские мили'
      },
      label: {
        metres: 'м',
        kilometres: 'км',
        feet: 'фут',
        landmiles: 'н.м.',
        nauticalmiles: 'м.м.'
      }
    }
  },
  reports: {
    title: 'Отчеты',
    initViewer: 'Инициализация отчета',
    initDesigner: 'Инициализация дизайнера отчетов',
    createReport: 'Отчет',
    createFolder: 'Папку',
    catalogizationTitle: 'Каталогизация отчета',
    removeTitle: 'Удаление отчета/папки',
    removeDialogMessage: 'Удалить \'{{name}}\' ?',
    catalog: 'Каталог',
    name: 'Наименование',
    nameHint: 'Укажите наименование отчета',
    folderNameHint: 'Укажите наименование папки',
    published: 'Опубликован',
    columns: {
      name: 'Наименование',
      views: 'Просмотров',
      lastView: 'Последний просмотр'
    },
    menu: {
      view: 'Просмотреть',
      rename: 'Переименовать',
      openInDesigner: 'Открыть в дизайнере',
      remove: 'Удалить',
      restore: 'Восстановить',
      catalogization: 'Каталогизация',
      downloadReportFile: 'Скачать файл отчета',
      uploadReportFile: 'Загрузить файл отчета',
      hide: 'Скрыть',
      publish: 'Опубликовать',
      clone: 'Клонировать'
    }
  },
  users: {
    title: 'Пользователи и роли',
    createUser: 'Пользователя',
    createRole: 'Роль',
    login: 'Логин',
    roles: 'Роли',
    fml: 'ФИО',
    selectRole: 'Укажите роль',
    emptyList: 'Список пуст',
    name: 'Наименование',
    roleNameErrorMessage: 'Укажите наименование роли',
    rolesForReports: 'Отчеты',
    rolesForDash: 'Дашборды',
    rolesForAdmin: 'Администрирование',
    rolesForBranches: 'Филиалы',
    rolesForReportsFolders: 'Папки отчетов',
    rolesForMT: 'Мобильная торговля',
    roleView: 'Просмотр',
    roleCreateAndEdit: 'Создание и редактирование',
    roleDash: 'Обзор',
    roleDashSales: 'Анализ продаж',
    roleDashDebt: 'Анализ дебиторской задолженности',
    roleDashProfit: 'Анализ маржи',
    roleAdminUsersRoles: 'Управление пользователями и ролями',
    roleMTViewGIS: 'Просмотр GPS треков',
    roleMTViewDevices: 'Просмотр списка устройста',
    roleMTViewGallery: 'Просмотр галереи фотографий',
    roleMTViewFiles: 'Просмотр файлов',
    accessToAnalytics: 'Разрешить доступ к "analytics"',
    removeRoleTitle: 'Удаление роли',
    removeRoleDialogMessage: 'Удалить роль \'{{name}}\' ?',
    menu: {
      edit: 'Редактировать',
      unactive: 'Заблокировать в Analytics',
      active: 'Разблокировать в Analytics',
      remove: 'Удалить'
    },
    columns: {
      name: 'Наименование',
      login: 'Логин'
    }
  },
  filters: {
    close: 'Закрыть',
    all: 'Все'
  },
  controls: {
    agGrid: {
      pinLeft: 'Слева',
      pinRight: 'Справа',
      noPin: 'Нет',
      toolPanel: 'Панель инструментов',

      equals: 'Равно',
      lessThan: 'Меньше',
      greaterThan: 'Больше',
      contains: 'Содержит',
      startsWith: 'Начинается с',
      endsWith: 'Заканчивается на',
      notEqual: 'Не равно',
      lessThanOrEqual: 'Меньше или равно',
      greaterThanOrEqual: 'Больше или равно',
      inRange: 'В диапазоне',
      notContains: 'Не содержит',

      page: 'Страница',
      more: 'Еще',
      to: 'по',
      of: 'из',
      next: 'Следующая',
      last: 'Последняя',
      first: 'Первая',
      previous: 'Предыдущая',
      loadingOoo: 'Загрузка...',

      selectAll: 'Выбрать все',
      searchOoo: 'Найти...',

      filterOoo: 'Фильтр...',
      applyFilter: 'Применить',

      columns: 'Столбцы',
      filters: 'Фильтры',
      groups: 'Группы',
      values: 'Значения',

      noRowsToShow: 'Нет данных для отображения',

      pinColumn: 'Закрепить',
      autosizeThiscolumn: 'Автоширина столбца',
      autosizeAllColumns: 'Автоширина всех столбцов',
      groupBy: 'Группировать',
      ungroupBy: 'Разгруппировать',
      resetColumns: 'Сбросить настройки',
      clearAllFilters: 'Очистить все фильтры',
      expandAll: 'Развернуть все',
      collapseAll: 'Свернуть все',
      export: 'Экспорт',
      csvExport: 'CSV',
      excelExport: 'Excel',

      sum: 'Сумма',
      min: 'Минимум',
      max: 'Максимум',
      count: 'Количество значений',
      average: 'Среднее',

      copy: 'Копировать',
      copyWithHeaders: 'Копировать с заголовком',
      paste: 'Вставить',

      group: 'Группа',
      rowGroupColumnsEmptyMessage: 'Перетащите сюда заголовок колонки, чтобы начать группировку по строкам',
    },
    datatable: {
      processing: 'Подождите...',
      search: 'Поиск:',
      lengthMenu: 'Показать _MENU_ записей',
      info: 'Записи с _START_ до _END_ из _TOTAL_ записей',
      infoEmpty: 'Записи с 0 до 0 из 0 записей',
      infoFiltered: '(отфильтровано из _MAX_ записей)',
      infoPostFix: '',
      loadingRecords: 'Загрузка записей...',
      zeroRecords: 'Записи отсутствуют.',
      emptyTable: 'В таблице отсутствуют данные',
      paginate: {
        first: 'Первая',
        previous: 'Предыдущая',
        next: 'Следующая',
        last: 'Последняя'
      },
      aria: {
        sortAscending: ': активировать для сортировки столбца по возрастанию',
        sortDescending: ': активировать для сортировки столбца по убыванию'
      }
    },
    daterangepicker: {
      format: 'DD.MM.YYYY',
      separator: ' - ',
      applyLabel: 'Применить',
      cancelLabel: 'Отмена',
      fromLabel: 'С',
      toLabel: 'По',
      customRangeLabel: 'Произвольно',
      daysOfWeek: [ 'ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ' ],
      monthNames: [ 'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь' ],
      firstDay: 1
    }
  }
};
