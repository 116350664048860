declare var COMMIT_HASH: string;

export const environment = {
  production: true,
  apiURL: 'https://analytics.hrp.su/api/',
  stimulsoftURL: 'https://analytics.hrp.su/api/stimulsoft/',
  passportURL: 'https://passport.hrp.su',
  clientId: 'analytics-ru',
  clientSecret: 'gbBaZsxx0xOtMoTCohVBzxXoUlCkFwa1',
  osmURL: 'https://analytics.hrp.su/osm/{z}/{x}/{y}.png',
  sentryZone: 'RU',
  sentryDsn: 'https://f4f2e4e273db4e919483cd03fc19557a@sentry.hrp.su/12',
  commitHash: COMMIT_HASH
};
