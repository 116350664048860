import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatProgressBarModule} from '@angular/material/progress-bar';

import {AppComponent} from './app.component';
import {UiService} from './services/ui.service';
import {Oauth2Module} from './auth/oauth2/oauth2.module';
import {ErrorsInterceptor} from './errors/errors.interceptor';
import {SecurityService} from './services/security.service';
import {NgSelectModule} from '@ng-select/ng-select';
import {AppRoutingModule} from './app-routing.module';
import {NavbarComponent} from './content/commons/navbar/navbar.component';
import {SidebarComponent} from './content/commons/sidebar/sidebar.component';
import {FooterComponent} from './content/commons/footer/footer.component';
import {TranslateModule} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import * as Sentry from '@sentry/angular';
import {Router} from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    Oauth2Module,
    BrowserModule,
    NgSelectModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    TranslateModule.forRoot()
  ],
  providers: [
    UiService,
    CookieService,
    SecurityService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler() },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [Sentry.TraceService], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
