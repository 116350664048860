import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import * as $ from 'jquery';
import 'daterangepicker';
import * as moment from 'moment';
import {Moment} from 'moment';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {UiService} from '../../../services/ui.service';
import {AuthService} from '../../../auth/auth.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {
  periodVisible = false;
  singleDateVisible = false;
  regionVisible = false;
  country: string;
  countries: Set<string>;
  progressVisible = false;
  avatarUrl: string = environment.passportURL + '/avatar/' + localStorage.getItem('current_user_id');

  private routerSubscription: Subscription;
  private countrySubscription: Subscription;
  private dataLoadSubscription: Subscription;

  constructor(private uiService: UiService,
              private router: Router,
              private translate: TranslateService,
              private authService: AuthService,
              private cdRef : ChangeDetectorRef) { }

  ngOnInit() {
    this.country = this.uiService.getCountry();
    this.countries = this.uiService.getCountriesList();
    this.dataLoadSubscription = this.uiService.onLoadDataProgress().subscribe(loading => {
      this.progressVisible = loading;
      this.cdRef.detectChanges();
    });
    this.countrySubscription = this.uiService.onChangeCountry().subscribe(country => {
      this.country = country;
    });
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((route: NavigationEnd) => {
        this.regionVisible = this.periodVisible = route.url === '/' || route.url.includes('dash');
        this.singleDateVisible = route.url.includes('gis-monitoring') || route.url.includes('devices/details');
      });
    $(document).on('mouseup', function () {
      $('#countryMenu').removeClass('show');
      $('#userMenu').removeClass('show');
    });
    $('#dateSingle').daterangepicker({
      opens: 'left',
      singleDatePicker: true,
      alwaysShowCalendars: true,
      minDate: moment('2017-01-01'),
      maxDate: moment('2030-12-31'),
      showDropdowns: true,
      timePicker: false,
      startDate: this.uiService.getSingleDate(),
      endDate: this.uiService.getSingleDate(),
      ranges: {
        [this.translate.instant('root.dateRanges.today')]: [
          moment(),
          moment()
        ],
        [this.translate.instant('root.dateRanges.yesterday')]: [
          moment().subtract(1, 'days'),
          moment().subtract(1, 'days')
        ],
        [this.translate.instant('root.dateRanges.theDayBeforeYesterday')]: [
          moment().subtract(2, 'days'),
          moment().subtract(2, 'days')
        ]
      },
      locale: this.translate.instant('controls.daterangepicker')
    }, (start) => {
      this.uiService.setSingleDate(start);
      this.showSingleDate(this.uiService.getSingleDate());
    });
    this.showSingleDate(this.uiService.getSingleDate());
    $('#dateRange').daterangepicker({
      opens: 'left',
      alwaysShowCalendars: true,
      minDate: moment('2017-01-01'),
      maxDate: moment('2030-12-31'),
      showDropdowns: true,
      timePicker: false,
      startDate: this.uiService.getPeriodStartDate(),
      endDate: this.uiService.getPeriodEndDate(),
      ranges: {
        [this.translate.instant('root.dateRanges.today')]: [
          moment(),
          moment()
        ],
        [this.translate.instant('root.dateRanges.yesterday')]: [
          moment().subtract(1, 'days'),
          moment().subtract(1, 'days')
        ],
        [this.translate.instant('root.dateRanges.currentWeek')]: [
          moment().startOf('week').add(1, 'days'),
          moment().endOf('week').add(1, 'days')
        ],
        [this.translate.instant('root.dateRanges.currentMonth')]: [
          moment().startOf('month'),
          moment().endOf('month')
        ],
        [this.translate.instant('root.dateRanges.currentYear')]: [
          moment().startOf('year'),
          moment().endOf('year')
        ],
        [this.translate.instant('root.dateRanges.lastWeek')]: [
          moment().subtract(1, 'week').startOf('week').add(1, 'days'),
          moment().subtract(1, 'week').endOf('week').add(1, 'days')
        ],
        [this.translate.instant('root.dateRanges.lastMonth')]: [
          moment().subtract(1, 'months').startOf('month'),
          moment().subtract(1, 'months').endOf('month')
        ],
        [this.translate.instant('root.dateRanges.lastYear')]: [
          moment().subtract(1, 'years').startOf('year'),
          moment().subtract(1, 'years').endOf('year')
        ]
      },
      locale: this.translate.instant('controls.daterangepicker')
    }, (start, end) => {
      this.uiService.setPeriod(start, end);
      this.showPeriod(this.uiService.getPeriodStartDate(), this.uiService.getPeriodEndDate());
    });
    this.showPeriod(this.uiService.getPeriodStartDate(), this.uiService.getPeriodEndDate());
  }

  ngOnDestroy(): void {
    if (this.routerSubscription != null) { this.routerSubscription.unsubscribe(); }
    if (this.countrySubscription != null) { this.countrySubscription.unsubscribe(); }
    if (this.dataLoadSubscription != null) { this.dataLoadSubscription.unsubscribe(); }
  }

  toggleSidebar() {
    $('body').toggleClass('sidebar-toggled');
    $('.sidebar').toggleClass('toggled');
  }

  countryClick() {
    $('#countryMenu').toggleClass('show');
  }

  selectCountry(country: string) {
    this.uiService.setCountry(country);
  }

  userClick() {
    $('#userMenu').toggleClass('show');
  }

  logout() {
    this.authService.logout();
  }

  private showPeriod(startDate: Moment, endDate: Moment) {
    const dateFormat = this.translate.instant('commons.dateFormat');
    $('#dateRange span')
      .html(endDate.diff(startDate, 'hours', true) > 24 ?
        startDate.format(dateFormat) + ' - ' + endDate.format(dateFormat) :
        startDate.format(dateFormat)
      );
  }

  private showSingleDate(date: Moment) {
    const dateFormat = this.translate.instant('commons.dateFormat');
    $('#dateSingle span').html(date.format(dateFormat));
  }
}
