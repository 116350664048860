import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ReportFolder} from './reports.service';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  constructor(private http: HttpClient) { }

  tree() {
    return this.http.get<UserTreeNode[]>(environment.apiURL + 'security/tree');
  }

  getBranchesList() {
    return this.http.get<Branch[]>(environment.apiURL +  'security/list_branches');
  }
  getReportsFoldersList() {
    return this.http.get<ReportFolder[]>(environment.apiURL +  'security/list_reports_folders');
  }

  loadUser(id: string) {
    const params = new HttpParams().set('id', id);
    return this.http.get<User>(environment.apiURL + 'security/load_user', { params });
  }
  saveUser(user: User) {
    return this.http.post<User>(environment.apiURL +  'security/save_user', user);
  }
  lockUser(id: string, lock: boolean) {
    const params = new HttpParams().set('id', id).set('lock', lock ? 'true' : 'false');
    return this.http.get<User>(environment.apiURL + 'security/lock_user', { params });
  }

  getRolesList() {
    return this.http.get<UserRole[]>(environment.apiURL +  'security/list_roles');
  }
  loadRole(id: string) {
    const params = new HttpParams().set('id', id);
    return this.http.get<UserRole>(environment.apiURL + 'security/load_role', { params });
  }
  saveRole(role: UserRole) {
    return this.http.post<UserRole>(environment.apiURL +  'security/save_role', role);
  }
  removeRole(id: string) {
    const params = new HttpParams().set('id', id);
    return this.http.delete(environment.apiURL + 'security/remove_role', { params });
  }
}

export enum Rights {
  ADMIN_USERS_AND_ROLES,
  VIEW_DASHBOARD,
  VIEW_DASHBOARD_SALES,
  VIEW_DASHBOARD_DEBT,
  VIEW_DASHBOARD_PROFIT,
  VIEW_REPORTS,
  EDIT_REPORTS,
  VIEW_MT_GALLERY,
  VIEW_MT_FILES,
  VIEW_MT_GPS_MONITORING,
  VIEW_MT_DEVICES
}

export interface CurrentUser {
  id: string;
  name: string;
  rights: string[];
}

/**
 * Модель элемента дерева пользователей
 */
export interface UserTreeNode {
  id: string;
  path: string[];
  title: string;
  username: string;
  active: boolean;
  removed: boolean;
  role: boolean;
  level: number;
}

export interface User {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  username: string;
  localActive: boolean;
  roles: string[];
}

export interface UserRole {
  id: string;
  name: string;
  rights: string[];
  accessToBranches: number[];
  accessToReportsFolders: string[];
}

export interface Branch {
  id: number;
  name: string;
  region: string;
  country: string;
}
