import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {UiService} from './services/ui.service';
import * as $ from 'jquery';
import {TranslateService} from '@ngx-translate/core';
import {locale as ruLang} from './i18n/ru';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  private httpErrorSubscribe: Subscription;

  constructor(private uiService: UiService,
              private translate: TranslateService) {
    translate.setTranslation('ru', ruLang);
    translate.use('ru');
  }

  ngOnInit(): void {
    this.httpErrorSubscribe = this.uiService.onHttpError().subscribe(() => {
      $('#errorToast')
        .addClass('show').fadeIn()
        .delay(5000).fadeOut('slow');
    });
  }

  ngOnDestroy(): void {
    this.httpErrorSubscribe.unsubscribe();
  }
}
