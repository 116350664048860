import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {UiService} from '../services/ui.service';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient, private uiService: UiService, private cookieService: CookieService) { }

  appInitialize(): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = new URL(window.location.href);
      const code = url.searchParams.get('code');
      if (url.href.includes('oauth') && code != null) {
        const clearUrl = this.clearOauth2URL(url);
        this.requestAccessToken(clearUrl, code).subscribe(token => {
          console.log('Token is: ' + token.access_token);
          document.cookie = 'token=' + token.access_token + '; max-age=86400; path=/';
          // this.cookieService.set('token', token.access_token, 24, '/');
          window.location.replace(clearUrl.href);
        }, () => {
          this.authError();
        });
      } else {
        this.uiService.init().subscribe(() => resolve(null), () => reject());
      }
    });
  }

  login() {
    const url = new URL(window.location.href);
    window.location.replace(`${environment.passportURL}/oauth/authorize?` +
      `response_type=code&client_id=${environment.clientId}&scope=identity&` +
      `redirect_uri=${this.addPathToURL(/*this.clearOauth2URL(url)*/new URL(url.origin), 'oauth')}`);
  }

  logout() {
    this.cookieService.delete('token');
    const url = new URL(window.location.href);
    window.location.replace(`${environment.passportURL}/logout?` +
      `redirect_to=${this.clearOauth2URL(url)}`);
  }

  forbidden() {
    window.location.replace(`${window.location.origin}/assets/403.html`);
  }

  authError() {
    window.location.replace(`${window.location.origin}/assets/401.html`);
  }

  notfound() {
    window.location.replace(`${window.location.origin}/assets/404.html`);
  }

  private requestAccessToken(redirectUri: URL, code: string) {
    const params = new HttpParams()
      .set('client_id', environment.clientId)
      .set('client_secret', environment.clientSecret)
      .set('grant_type', 'authorization_code')
      .set('code', code)
      .set('redirect_uri', this.addPathToURL(redirectUri, 'oauth').href);
    return this.http.post<Token>(environment.passportURL +  '/oauth/token', null, { params });
  }

  private clearOauth2URL(url: URL): URL {
    if (url.href.includes('/oauth')) {
      return new URL(url.href.substring(0, url.href.indexOf('/oauth')));
    } else {
      return url;
    }
  }

  private addPathToURL(url: URL, path: string): URL {
    if (url.href.endsWith('/')) {
      return new URL(url.href + path);
    } else {
      return new URL(url.href + '/' + path);
    }
  }
}

interface Token {
  access_token: string;
  expires_in: number;
  scope: string;
  token_type: string;
}
