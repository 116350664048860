import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import * as Sentry from '@sentry/angular';
import {LicenseManager} from 'ag-grid-enterprise';
import {Integrations} from '@sentry/tracing';

LicenseManager.setLicenseKey('UltimateLicense_MzI0NzIxMzMyMDAwMDA=8a8da56e863445643b6081f1132441c7');

if (environment.production) {
  Sentry.init({
    dsn: environment.sentryDsn,
    release: environment.commitHash,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost', 'https://analytics.hrp.su/api', 'https://passport.hrp.su/'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
  Sentry.setTag('zone', environment.sentryZone);
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
