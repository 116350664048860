import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {UiService} from '../services/ui.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private threadCount = 0;

  constructor(private auth: AuthService, private uiService: UiService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone();
    this.uiService.setDataLoadingProgress(true);
    this.threadCount++;
    return next.handle(request).pipe(
      map((event: HttpResponse<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.auth.login();
        }
        if (error.status === 403) {
          this.auth.forbidden();
        }
        if (error.status === 404) {
          this.auth.notfound();
        }
        return throwError(error);
      }),
      finalize(() => {
        this.threadCount--;
        if (this.threadCount === 0) {
          this.uiService.setDataLoadingProgress(false);
        }
      })
    );
  }
}
