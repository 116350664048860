<nav class="navbar navbar-expand navbar-dark bg-dark static-top" style="height: 56px;">
  <img width="40px" height="40px" style="padding-right: 7px;" alt="logo" src="../../../../assets/logo.svg"/>
  <a class="navbar-brand mr-1" routerLink="/">{{'root.title' | translate}}</a>
  <button class="btn btn-link btn-sm text-white order-0 order-sm-0" id="sidebarToggle" href="#" (click)="toggleSidebar()">
    <i class="fas fa-bars"></i>
  </button>

  <form class="form-inline ml-auto mr-0 my-2 my-md-0" style="flex-flow: nowrap;">
    <div class="input-group" [hidden]="!singleDateVisible">
      <div id="dateSingle" class="nav-input">
        <i class="fa fa-calendar"></i>&nbsp;
        <span style="padding-right: 5px;"></span>
        <i class="fa fa-caret-down"></i>
      </div>
    </div>
    <div class="input-group" [hidden]="!periodVisible">
      <div id="dateRange" class="nav-input">
        <i class="fa fa-calendar"></i>&nbsp;
        <span style="padding-right: 5px;"></span>
        <i class="fa fa-caret-down"></i>
      </div>
    </div>
    <div class="input-group" [hidden]="!regionVisible">
      <div id="country" class="nav-input" (click)="countryClick()">
        <i class="fa fa-globe"></i>&nbsp;
        <span style="padding-right: 5px;">{{country}}</span>
        <i class="fa fa-caret-down"></i>
      </div>
      <div id="countryMenu" class="dropdown-menu dropdown-menu-right" aria-labelledby="country">
        <a class="dropdown-item" href="javascript:void(0);" (click)="selectCountry(c)" *ngFor="let c of countries">{{c}}</a>
      </div>
    </div>
    <div class="input-group">
      <img [src]="avatarUrl" id="avatar" alt="avatar" class="avatar" (click)="userClick()">
      <div id="userMenu" class="dropdown-menu dropdown-menu-right" aria-labelledby="avatar">
        <a class="dropdown-item" href="javascript:void(0);" (click)="logout()">{{'root.logout' | translate}}</a>
      </div>
    </div>
  </form>
</nav>
<mat-progress-bar style="position: absolute; top: 52px;" [mode]="'indeterminate'" *ngIf="progressVisible"></mat-progress-bar>
