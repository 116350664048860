<ul class="sidebar navbar-nav toggled" id="sidebar-icons-table">
  <li class="nav-item" [ngClass]="{'active': selectedItem == 'menu-browse'}" *ngIf="showDashboard">
    <a class="nav-link" routerLink="/">
      <i class="fas fa-fw fa-chart-area"></i>
      <span>{{'root.sideMenu.browse' | translate}}</span>
    </a>
  </li>
  <li class="nav-item" [ngClass]="{'active': selectedItem == 'menu-reports'}" *ngIf="showReports">
    <a class="nav-link" routerLink="/reports">
      <i class="fas fa-fw fa-table"></i>
      <span>{{'root.sideMenu.reports' | translate}}</span>
    </a>
  </li>
  <li class="nav-item" [ngClass]="{'active': selectedItem == 'menu-gallery'}" *ngIf="showGallery">
    <a class="nav-link" routerLink="/gallery">
      <i class="fas fa-fw fa-camera"></i>
      <span>{{'root.sideMenu.gallery' | translate}}</span>
    </a>
  </li>
  <li class="nav-item" [ngClass]="{'active': selectedItem == 'menu-files'}" *ngIf="showFiles">
    <a class="nav-link" routerLink="/files">
      <i class="fas fa-fw fa-folder"></i>
      <span>{{'root.sideMenu.files' | translate}}</span>
    </a>
  </li>
  <li class="nav-item" [ngClass]="{'active': selectedItem == 'menu-devices'}" *ngIf="showDevices">
    <a class="nav-link" routerLink="/devices">
      <i class="fas fa-fw fa-tablet-alt"></i>
      <span>{{'root.sideMenu.devices' | translate}}</span>
    </a>
  </li>
  <li class="nav-item" [ngClass]="{'active': selectedItem == 'menu-gis-monitoring'}" *ngIf="showGisMonitoring">
    <a class="nav-link" routerLink="/gis-monitoring">
      <i class="fas fa-fw fa-map-marked-alt"></i>
      <span>{{'root.sideMenu.gisMonitoring' | translate}}</span>
    </a>
  </li>
  <li class="nav-item" [ngClass]="{'active': selectedItem == 'menu-users'}" *ngIf="showUsers">
    <a class="nav-link" routerLink="/users">
      <i class="fas fa-fw fa-users"></i>
      <span>{{'root.sideMenu.users' | translate}}</span>
    </a>
  </li>
</ul>
